import React from "react";
import {
  Container,
  Header,
  HorizontalRule,
  Logo,
  Paragraphs,
  StyledLink,
  Title,
} from "../styles/PageStyles";
import VeidtLogo from "../componets/logo";

const CaligulaPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo />
        <Title>Reach</Title>
      </Header>
      <Paragraphs>
        Reach represents the next level of our venture debt strategy. This tier
        focuses on larger investments, typically ranging from $500K–$750K,
        targeting high-potential startups with significant growth opportunities.
        These companies demonstrate strong fundamentals and scalability, making
        them ideal candidates for impactful funding. By providing substantial
        capital, Reach empowers startups to accelerate their growth, capture
        market share, and unlock new levels of success. For investors, it’s an
        opportunity to participate in the scaling phase of promising ventures,
        maximizing returns while mitigating risk through our disciplined
        approach.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/apply-now">Apply Now</StyledLink>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default CaligulaPage;
