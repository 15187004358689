import React from "react";
import {
  Container,
  Header,
  HorizontalRule,
  Logo,
  Paragraphs,
  StyledLink,
  Title,
} from "../styles/PageStyles";
import VeidtLogo from "../componets/logo";

const OzymandiasPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo />
        <Title>Core</Title>
      </Header>
      <Paragraphs>
        Core is the foundation of our venture debt strategy. We focus on making
        strategic investments in stable, revenue-generating startups with proven
        fundamentals. These investments, typically ranging from $100K–$200K, are
        designed to support companies with strong financial performance and
        scalable business models. By prioritizing security and growth, Core
        provides a solid base for both startups and investors to thrive.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/apply-now">Apply Now</StyledLink>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default OzymandiasPage;
