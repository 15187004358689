import React from "react";
import {
  Container,
  Header,
  HorizontalRule,
  Logo,
  Paragraphs,
  StyledLink,
  Title,
} from "../styles/PageStyles";
import VeidtLogo from "../componets/logo";

const WhatPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo />
        <Title>Our Beliefs</Title>
      </Header>
      <Paragraphs>
        At Veidt, we believe in the transformative power of entrepreneurship and
        the importance of supporting innovation that can make a global impact.
        We are driven by a commitment to integrity, excellence, and the belief
        that thoughtful capital allocation can change the world. We believe in
        working with driven innovators who are not just looking to build
        successful businesses but are also striving to make a meaningful
        difference in their communities and industries. For us, venture debt is
        not just about financial returns—it’s about empowering visions, enabling
        growth, and creating lasting value for all stakeholders. At our core, we
        believe that every challenge is an opportunity, and every venture has
        the potential to achieve greatness with the right support and guidance.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default WhatPage;
