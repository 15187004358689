import React from "react";
import {
  Container,
  Header,
  HorizontalRule,
  Logo,
  Paragraphs,
  StyledLink,
  Title,
} from "../styles/PageStyles";
import VeidtLogo from "../componets/logo";

const WhoPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo />
        <Title>Who We Are</Title>
      </Header>
      <Paragraphs>
        Veidt is a venture debt strategy company with a mission to empower
        founders and investors alike. We are deeply embedded in the startup
        ecosystems of Los Angeles, New York, and Austin, leveraging our
        connections to secure opportunities that drive innovation and growth. As
        former founders ourselves, we understand the intricacies of building and
        scaling businesses, and we use this expertise to forge partnerships that
        are both strategic and impactful. At Veidt, we don’t just invest
        capital; we bring strategic insights, hands-on support, and a commitment
        to excellence that sets us apart. Our network spans across industries,
        and our reputation is built on trust, innovation, and the consistent
        ability to deliver value for both startups and investors.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default WhoPage;
