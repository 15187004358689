import React from "react";
import {
  Container,
  Header,
  HorizontalRule,
  Logo,
  Paragraphs,
  StyledLink,
  Title,
} from "../styles/PageStyles";
import VeidtLogo from "../componets/logo";

const NeroPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo />
        <Title>Explore</Title>
      </Header>
      <Paragraphs>
        Explore is the experimental tier of our venture debt strategy, focusing
        on smaller investments ranging from $50K–$100K. This approach allows us
        to support emerging ventures with unique or early-stage ideas that show
        potential for high impact and innovation. Through Explore, we aim to
        identify and nurture startups that may not yet have significant revenue
        but are poised to disrupt their industries. These investments offer a
        chance to strategically support bold, creative ventures while
        maintaining a disciplined and thoughtful approach to risk. Explore is
        where we seek to uncover the next big opportunities and pave the way for
        future growth.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/apply-now">Apply Now</StyledLink>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default NeroPage;
