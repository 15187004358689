import React from "react";
import {
  Container,
  Header,
  HorizontalRule,
  Logo,
  Paragraphs,
  StyledLink,
  Title,
} from "../styles/PageStyles";
import VeidtLogo from "../componets/logo";

const HowPage = () => {
  return (
    <Container>
      <Header>
        <VeidtLogo />
        <Title>Our Opperation</Title>
      </Header>
      <Paragraphs>
        Our approach is rooted in precision and adaptability. We operate by
        conducting thorough due diligence to identify startups with strong
        fundamentals, scalable models, and the potential for sustainable growth.
        Our venture debt fund is structured to balance risk and reward, offering
        a secure pathway for investors while providing startups with the capital
        they need to thrive. We divide our investments into three tiers—Core,
        Reach, and Explore—allowing us to tailor our support based on the stage
        and potential of each venture. Our focus is on building long-term
        partnerships, and we pride ourselves on going beyond funding by
        providing founders with the resources, advice, and networks they need to
        succeed. Every decision we make is backed by data, deep market research,
        and a clear vision for creating shared success.
      </Paragraphs>
      <HorizontalRule />
      <StyledLink to="/">back</StyledLink>
    </Container>
  );
};

export default HowPage;
