// src/App.js
import React from 'react';
import { Container, Header, Heading, HorizontalRule, List, ListItem, Logo, Paragraphs, StyledA, StyledLink, Title } from '../styles/PageStyles';
import VeidtLogo from '../componets/logo';




function HomePage() {
  return (
      <Container>
        <Header>
          <VeidtLogo/>
          <Title>Veidt</Title>
        </Header>
        <HorizontalRule />
        <Paragraphs>
        Veidt is a Venture Debt strategy company specializing in providing non-dilutive financing solutions for founders who are ready to scale. We help startups overcome competition, secure strategic funding, and expand into new markets, all while preserving equity. Our expertise lies in forging deep relationships within the startup ecosystems of Los Angeles, New York, and Austin, allowing us to access top-tier opportunities. With a background in analytical research and expert data synthesis, we provide founders with strategic insights that drive sustainable growth. Veidt’s approach to venture debt combines meticulous information gathering with hands-on support, enabling us to structure tailored investments that align with both startup and investor goals. For our LPs, we offer a safe, high-yield entry into the U.S. market, leveraging our proven ability to mitigate risks through disciplined capital allocation and strategic partnerships. At Veidt, we’re more than investors – we’re partners in growth, ensuring our founders’ visions succeed while delivering secure returns for our investors.
        </Paragraphs>
        
        
        <Heading>People</Heading>
        <HorizontalRule />
        <List>
          <ListItem><StyledA href="mailto:Henriagfranco@gmail.com" target="_blank">Henri Francois</StyledA></ListItem>
          <ListItem><StyledA href="mailto:thamid.shanto011@gmail.com" target="_blank">Thamid Shanto</StyledA></ListItem>
        </List>
        
        <Heading>Offerings</Heading>
        <HorizontalRule />
        <List>
          <ListItem><StyledLink to="/core">Core</StyledLink>, focused investments in stable, revenue-generating startups.</ListItem>
          <ListItem><StyledLink to="/reach">Reach</StyledLink>, larger checks for high-potential growth opportunities.</ListItem>
          <ListItem><StyledLink to="/explore">Explore</StyledLink>, smaller bets on emerging, experimental ventures.</ListItem>
        </List>
        
        <Heading>Beliefs</Heading>
        <HorizontalRule />
        <List>
          <ListItem><StyledLink to="/who">Who we are</StyledLink></ListItem>
          <ListItem><StyledLink to="/how">How we operate</StyledLink></ListItem>
          <ListItem><StyledLink to="/what">What we believe in</StyledLink></ListItem>
        </List>
      </Container>
  );
}

export default HomePage;